'use client'

import { ReactNode } from 'react'
import { AlertCircleFill } from '~/core/ui/FillIcons'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { cn } from './utils'

type FCC<Props = Record<string, unknown>> = React.FC<
  React.PropsWithChildren<Props>
>

interface FormControlItemProps {
  destructive?: boolean
  destructiveText?: string | ReactNode
  label?: string | ReactNode
  labelRequired?: boolean
  helpText?: ReactNode
  mode?: 'vertical' | 'horizontal'
  iconMenus?: LucideIconName
  helpIcon?: ReactNode
  widthLabel?: string
  labelExtraComponent?: ReactNode
}

const FormControlItem: FCC<FormControlItemProps> = (props) => {
  const {
    destructive = false,
    destructiveText,
    label = '',
    labelRequired = false,
    helpText = '',
    children,
    mode = 'vertical',
    iconMenus,
    helpIcon,
    widthLabel = 'min-w-[116px]',
    labelExtraComponent
  } = props

  if (mode === 'horizontal') {
    return (
      <div className="flex space-x-2">
        {label ? (
          <div
            className={cn(
              'flex h-full items-center space-x-2 py-1.5',
              widthLabel
            )}>
            {iconMenus ? <IconWrapper name={iconMenus} size={16} /> : null}

            <div className="flex items-center space-x-1">
              <p className="text-sm text-gray-600 dark:text-gray-300">
                {label}
              </p>
              {labelRequired ? (
                <span className="text-sm font-medium text-red-500 dark:text-red-500">
                  *
                </span>
              ) : null}
              {helpIcon ? helpIcon : null}
            </div>
          </div>
        ) : null}

        <div className="flex flex-1 flex-col">
          {children}

          {helpText && destructive === false ? (
            <div className="mt-0.5">
              <p className="text-sm font-normal text-gray-600 dark:text-gray-400">
                {helpText}
              </p>
            </div>
          ) : null}

          {destructive ? (
            <div className="mt-0.5 flex items-center">
              <div className="min-w-[16px]">
                <AlertCircleFill />
              </div>
              <div className="ml-1">
                <p className="text-sm font-normal text-red-500 dark:text-red-500">
                  {destructiveText}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  return (
    <>
      {label ? (
        <div className="mb-1 flex items-center justify-between">
          <div className="flex items-center">
            <p className="text-sm font-medium text-gray-600 dark:text-gray-300">
              {label}
              {labelRequired ? (
                <span className="pl-1 text-sm font-normal text-red-500 dark:text-red-500">
                  *
                </span>
              ) : null}
            </p>
            {helpIcon ? helpIcon : null}
          </div>
          {labelExtraComponent}
        </div>
      ) : null}

      {children}

      {helpText && destructive === false ? (
        <div className="mt-1">
          <p className="text-sm font-normal text-gray-600 dark:text-gray-400">
            {helpText}
          </p>
        </div>
      ) : null}

      {destructive ? (
        <div className="mt-1 flex">
          <div className="min-w-[16px] h-5 flex justify-center items-center">
            <AlertCircleFill />
          </div>
          <div className="ml-1">
            <p className="text-sm font-normal text-red-500 dark:text-red-500">
              {destructiveText}
            </p>
          </div>
        </div>
      ) : null}
    </>
  )
}

FormControlItem.displayName = 'FormControlItem'

export { FormControlItem }
export type { FormControlItemProps }
